import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import LongFormTextItem from "~components/molecules/long_form_text_item/long_form_text_item";
import formatDate from "~utils/date_utils/format_date/format_date";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import {
  dateStyle,
  longFormTextBodyStyle,
  longFormTextWrapperStyle,
} from "./template_long_form_text_entry.module.scss";

export default function TemplateLongFormTextEntry(props) {
  const { story, pageContext } = usePageData(props);

  const { subNavData } = pageContext;

  const { title, subtitle, date, body, ...rest } = story;

  return (
    <TemplateGlobalEntry {...props} headerColor="blue" subNavData={subNavData}>
      <div className={longFormTextWrapperStyle}>
        <Contain small>
          <SbEditable content={rest}>
            <div className={longFormTextBodyStyle}>
              <Heading visualLevel="2" seoLevel="1">
                {title}
              </Heading>
              {subtitle && subtitle.length && (
                <Heading visualLevel="3" seoLevel="2">
                  {subtitle}
                </Heading>
              )}
              {date && date.length && (
                <div className={dateStyle}>Dated: {formatDate(date)}</div>
              )}
              {body.map((child) => {
                return <LongFormTextItem {...child} key={child._uid} />;
              })}
            </div>
          </SbEditable>
        </Contain>
      </div>
    </TemplateGlobalEntry>
  );
}

TemplateLongFormTextEntry.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.shape({}),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  query LongFormTextQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
