import React, { useEffect } from "react";
import PropTypes from "prop-types";
import slugify from "slugify";
import SbEditable from "storyblok-react";
import Heading from "~components/atoms/heading/heading";
import useIntersect from "~hooks/use_intersect/use_intersect";
import { RICH_TEXT } from "../../../prop_types";
import RichTextWithBloks from "../rich_text/rich_text_with_bloks";
import * as classes from "./long_form_text_item.module.scss";

export default function LongFormTextItem({
  body,
  callbackWhenInView,
  title,
  ...rest
}) {
  const id = slugify(title, { lower: true, strict: true });

  const [ref, inView] = useIntersect({ rootMargin: "-25% 0% -50% 0%" });

  useEffect(() => {
    if (inView?.isIntersecting) {
      callbackWhenInView(title);
    }
  }, [callbackWhenInView, id, inView, title]);

  return (
    <SbEditable content={rest} key={rest._uid}>
      <div
        data-testid="long-form-text-item"
        id={id}
        className={classes.longFormTextItemWrapper}
      >
        <div ref={ref} className={classes.headingWrapper}>
          <Heading seoLevel="3" visualLevel="5">
            {title}
          </Heading>
        </div>
        <RichTextWithBloks text={body} {...rest} />
      </div>
    </SbEditable>
  );
}

LongFormTextItem.defaultProps = {
  body: [],
  callbackWhenInView: () => {},
};

LongFormTextItem.propTypes = {
  body: RICH_TEXT,
  callbackWhenInView: PropTypes.func,
  title: PropTypes.string.isRequired,
};
